import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { AuthResponse, RegisterUser, VerifyPhone, Vote } from '@models';
import { BehaviorSubject, Observable, Subject, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { TokenStorageService } from './token-storage.service';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};

@Injectable({
  providedIn: 'root',
})
export class AwardService {
  private message = new BehaviorSubject(null);
  API_URL = environment.award_api;

  errors$: Subject<string> = new Subject<string>();

  constructor(
    private http: HttpClient,
    private tokenStorage: TokenStorageService,
  ) {}

  register(form: RegisterUser): Observable<RegisterUser> {
    return this.http
      .post<RegisterUser>(`${this.API_URL}/v1/register`, form, httpOptions)
      .pipe(
        tap((value) => this.tokenStorage.savePhone(value)),
        catchError((error: unknown) => {
          Object.values((error as any).error).map((x: any) => {
            x.map((e: string) => {
              if (e === 'Enter a valid phone number.') {
                x[x.indexOf(e)] = 'Введите корректный номер телефона';
                this.errors$.next(x);
              }
            });
          });
          return throwError(error);
        }),
      );
  }

  verifyPhone(form: VerifyPhone): Observable<any> {
    return this.http
      .get<AuthResponse>(
        `${this.API_URL}/v1/verify_phone/${form.sms_code}&${form.phone_number}`,
        httpOptions,
      )
      .pipe(
        tap((value) => this.tokenStorage.saveToken(value)),
        map((value) => {
          if (value.detail) {
            this.errors$.next(
              'Введенный код не совпадает или срок его действия истек',
            );
            throw value;
          } else if (value.voted === true) {
            this.errors$.next('Вы уже проголосовали');
            throw value;
          }
        }),
      );
  }

  vote(form: Vote): Observable<Vote> {
    return this.http
      .post<Vote>(`${this.API_URL}/v1/vote`, form, httpOptions)
      .pipe(
        catchError((error: unknown) => {
          (error as any).error.map((x: any) => {
            if (x === "{'user already voted'}") {
              this.errors$.next('Вы уже проголосовали');
            }
          });
          return throwError(error);
        }),
      );
  }
}
