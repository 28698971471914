import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { WINDOW } from '@ng-web-apis/common';

@Injectable({
  providedIn: 'root',
})
export class ClientWidthService {
  isMobile = false;

  constructor(
    @Inject(WINDOW) private readonly windowRef: Window,
    @Inject(DOCUMENT) private readonly documentRef: Document,
    @Inject(PLATFORM_ID) private platform: object,
  ) {}

  getIsMobile(): void {
    const width = this.documentRef.documentElement.clientWidth;
    const breakpoint = 1024;
    this.isMobile = width < breakpoint;
  }

  makeChangesOnResize(): void {
    if (isPlatformBrowser(this.platform)) {
      this.windowRef.onresize = () => {
        this.getIsMobile();
      };
    }
  }
}
