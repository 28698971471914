import { Injectable } from '@angular/core';
import { AuthResponse, RegisterResponse } from '@models';

import { SessionStorageService } from './session-storage.service';

const TOKEN_KEY = 'auth-token';
const PHONE_KEY = 'phone';

@Injectable({
  providedIn: 'root',
})
export class TokenStorageService {
  constructor(private sessionStorage: SessionStorageService) {}

  public getPhone(): null | string {
    return this.sessionStorage.getTokenFromSessionStorage(PHONE_KEY);
  }

  public getToken(): null | string {
    return this.sessionStorage.getTokenFromSessionStorage(TOKEN_KEY);
  }

  public logout(): void {
    this.sessionStorage.clearSessionStorage();
  }

  public savePhone(response: RegisterResponse): void {
    this.sessionStorage.removeTokenFromSessionStorage(PHONE_KEY);
    this.sessionStorage.setTokenToSessionStorage(
      PHONE_KEY,
      response.phone_number,
    );
  }

  public saveToken(response: AuthResponse): void {
    this.sessionStorage.removeTokenFromSessionStorage(TOKEN_KEY);
    this.sessionStorage.setTokenToSessionStorage(TOKEN_KEY, response.token);
  }
}
