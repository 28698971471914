import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { MetaTag } from '@models';

@Injectable({
  providedIn: 'root',
})
export class SEOService {
  private descriptionMeta = 'og:description';

  private titleMeta = 'og:title';
  constructor(
    private title: Title,
    private meta: Meta,
  ) {}

  private setTags(tags: MetaTag[]): void {
    tags.forEach((siteTag) => {
      this.meta.updateTag({ content: siteTag.value, property: siteTag.name });
    });
  }

  public setSocialMediaTags(title: string, description: string): void {
    const tags = [
      new MetaTag(this.titleMeta, title),
      new MetaTag(this.descriptionMeta, description),
    ];
    this.setTags(tags);
  }

  public setTitle(title: string): void {
    this.title.setTitle(title);
  }
}
