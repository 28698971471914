import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment';

@Injectable({
  providedIn: 'root',
})
export class GalleryService {
  API_URL = environment.api_url;
  getAlbum = (id: string, page: number, per_page: number) => {
    return this.http.get(
      `${this.API_URL}/v1/fotkiV2/album?id=${id}&page=${page}&per_page=${per_page}`,
    );
  };

  getAlbumsTree = () => {
    return this.http.get(`${this.API_URL}/v1/fotkiV2/collections-tree`);
  };

  constructor(private http: HttpClient) {}
}
