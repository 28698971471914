import { DOCUMENT, Location } from '@angular/common';
import { DestroyRef, Inject, Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NavigationEnd, Router } from '@angular/router';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ScrollService {
  ViewInit = false;

  constructor(
    @Inject(DOCUMENT) private readonly documentRef: Document,
    private router: Router,
    private location: Location,
    private destroyRef: DestroyRef,
  ) {}

  public returnBack(): void {
    this.location.back();
  }

  setView(): void {
    this.ViewInit = true;
  }

  //TODO: добавить параметр к setTimeout
  public triggerScrollTo(element: string): void {
    if (this.router.url !== '/') {
      this.router.navigateByUrl('/');
      this.router.events
        .pipe(take(15), takeUntilDestroyed(this.destroyRef))
        .subscribe((event) => {
          if (event instanceof NavigationEnd) {
            setTimeout(() => {
              this.documentRef
                .getElementById(element)
                ?.scrollIntoView({ block: 'start' });
            });
          }
        });
    } else {
      setTimeout(() => {
        this.documentRef
          .getElementById(element)
          ?.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }, 1000);
    }
  }
}
