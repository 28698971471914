import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { EventType, Person } from '@swagger/models';
import dayjs from 'dayjs';
import { Observable, combineLatest, forkJoin } from 'rxjs';
import { map, switchMap, take, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ProgramService {
  API_URL = environment.apiV2_url;
  cache: Record<string, any> = {};

  time1CToDate = (date: string): Date | null => {
    let formattedDate: Date | null = null;

    if (date) {
      // 2017-10-09T14:30:00
      formattedDate = dayjs(date).toDate();
    }

    return formattedDate;
  };

  constructor(private http: HttpClient) {}

  getEventTypes(): Observable<EventType[]> {
    if (!this.cache['event_types']) {
      return this.http
        .get<any[]>(`${this.API_URL}/api/v1/event_type/list`)
        .pipe(
          map((res) => {
            return res.map((el) => {
              return {
                id: el['id'],
                name: el['Description'],
              };
            });
          }),
          tap((data) => {
            this.cache['event_types'] = data;
          }),
        );
    } else {
      return new Observable<EventType[]>((observer) => {
        observer.next(this.cache['event_types']);
        observer.complete();
      });
    }
  }

  getEventsListByStage(themeId: string, speakerId?: string): Observable<any> {
    let url = `${this.API_URL}/api/v1/event/list?theme=${themeId}`;
    if (speakerId != undefined) url += `&speakers=${speakerId}`;

    const eventType$ = this.getEventTypes();
    const eventByStage$ = this.http.get<any[]>(url);

    return forkJoin([eventType$, eventByStage$]).pipe(
      map(([eventType, eventByStage]) => {
        return eventByStage.map((result) => {
          let event_type = eventType.find(
            (event_type) => event_type['id'] === result['id'],
          );
          if (!event_type) {
            event_type = {
              id: result['id'],
              name: 'undefined',
            };
          }

          const speakers: Person[] = result['speakers'].map((el: any) => {
            return {
              id: el.id,
              name: '',
            };
          });

          return {
            area: {},
            area_events: result['area_events'],
            id: result['id'],
            name: result['name'],
            speakers,
            time_finish: this.time1CToDate(result['time_finish']),
            time_start: this.time1CToDate(result['time_start']),
          };
        });
      }),
      switchMap((results) => {
        const participants_ids: unknown[] = [];
        let areas_ids: unknown[] = [];

        results.map((result) => {
          result.area_events.map((id: unknown) => areas_ids.push(id));

          result.speakers.map((el) => {
            participants_ids.push(el.id);
          });
        });

        areas_ids = areas_ids
          .filter((v, i, a) => a.indexOf(v) === i)
          .filter((item) => typeof item === 'number');

        const speakers_request$ = this.http.get<any[]>(
          `${this.API_URL}/api/v1/person/list?theme=${themeId}&ids=${participants_ids}`,
        );
        const areas_request$ = this.http.get<any[]>(
          `${this.API_URL}/api/v1/area/list?ids=${areas_ids}`,
        );

        return combineLatest([speakers_request$, areas_request$]).pipe(
          take(1),
          map((data) =>
            results.map((result) => {
              const area = data[1].filter(
                (a) => a.id === result.area_events[0],
              );
              if (area.length === 1) {
                Object.assign(result['area'], area[0]);
              }

              result.speakers.map((speaker) => {
                const data_speaker = data[0].filter(
                  (data_speaker) => data_speaker.id === speaker.id,
                );
                if (data_speaker.length === 1) {
                  Object.assign(speaker, data_speaker[0]);
                }
                return speaker;
              });
              return result;
            }),
          ),
        );
      }),
    );
  }
}
