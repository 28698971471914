import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MenuItem } from '@models';
import { WINDOW } from '@ng-web-apis/common';

import { ScrollService } from './scroll.service';

@Injectable({
  providedIn: 'root',
})
export class MenuService {
  constructor(
    private scrollService: ScrollService,
    private router: Router,
    @Inject(WINDOW) private readonly windowRef: Window,
  ) {}

  navButtonAction(button: MenuItem): void {
    if (button.isLink) {
      this.windowRef.open(button.url, '_blank');
    } else if (button.url) {
      this.router.navigate([button.url]);
    } else if (button.scrollToElement) {
      this.scrollService.triggerScrollTo(button.scrollToElement);
    }
  }
}
