import { Inject, Injectable, InjectionToken } from '@angular/core';
import { StorageService } from 'ngx-webstorage-service';

export const BESTCHEFS_SERVICE_STORAGE = new InjectionToken<StorageService>(
  'BESTCHEFS_SERVICE_STORAGE',
);

@Injectable({
  providedIn: 'root',
})
export class SessionStorageService {
  constructor(
    @Inject(BESTCHEFS_SERVICE_STORAGE) private storage: StorageService,
  ) {}

  public clearSessionStorage(): void {
    this.storage.clear();
  }

  public getTokenFromSessionStorage(key: string): null | string {
    return this.storage.get(key);
  }

  public hasTokenInSessionStorage(key: string): boolean {
    return this.storage.has(key);
  }

  public removeTokenFromSessionStorage(key: string): void {
    this.storage.remove(key);
  }

  public setTokenToSessionStorage(key: string, value: string): void {
    this.storage.set(key, value);
  }
}
